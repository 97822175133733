import pluralize from "pluralize";

const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
}

const plurarizeStr = str => {
    return pluralize.plural(str);
}

const jsUcfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const formatPropType = str => {
    return jsUcfirst(plurarizeStr(camelizedString(str))) 
}

function stripHtmlTags(html) {
    if(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }
    return "";
  }
  
function formatBytes(bytes) {
    bytes = +bytes
    if (bytes === 0) return '0 Bytes';
    
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    
    return parseFloat(Math.round(bytes / Math.pow(1024, i))) + ' ' + sizes[i];
}

function formatPhoneNumber(phone) {
  const cleaned = ('' + phone).replace(/\D/g, '');
  if (cleaned.length === 10) {
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  return phone;
}
  
export { formatPropType , stripHtmlTags , formatBytes , formatPhoneNumber};